import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import {FC, useEffect, useState} from "react";
import {EditModalProps} from "./interfaces";
import axiosInstance from "../../App/AuthProvider/request";

export const EditModal: FC<EditModalProps> = (props) => {
    let {
        keyId,
        username,
        service,
        open,
        closeModal,
        updateList
    } = props;

    const [editUsername, setUsername] = useState<string>('');
    const [editService, setService] = useState<string>('');

    useEffect(() => {
        setService(service);
        setUsername(username)
    }, [service, username])

    return (
        <React.Fragment>

            <Dialog
                open={open}
                onClose={closeModal}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());

                        axiosInstance.put('/api/' + keyId,
                            JSON.stringify({
                                username: formJson.username,
                                service: formJson.service,
                            })
                        )
                            .then((response) => {
                                if (response.status === 200)
                                    updateList()
                            })

                        closeModal();
                    },
                }}
            >
                <DialogTitle>
                    Редактирование аккаунта
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Для редактирования аккаунта введите следующие данные:
                    </DialogContentText>
                    <TextField
                        label="Сервис"
                        variant="outlined"
                        id="service"
                        name="service"
                        value={editService}
                        onChange={(event) => setService(event.target.value)}
                        fullWidth
                        required
                        margin="dense"/>
                    <TextField
                        label="Логин"
                        variant="outlined"
                        id="username"
                        name="username"
                        value={editUsername}
                        onChange={(event) => setUsername(event.target.value)}
                        fullWidth
                        required
                        margin="dense"/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal}>Отменить</Button>
                    <Button type="submit">Изменить</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}