import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {FC, useState} from "react";
import {MenuProps} from "../Menu/interfaces";
import {useAuth} from "../../App/AuthProvider/AuthProvider";

export const RegisterModal: FC<MenuProps> = (props) => {
    const {
        isOpen,
        closeModal
    } = props;

    const [registerData, setRegisterData] = useState({
        username: "",
        email: "",
        password: "",
    });

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        setRegisterData({
            ...registerData,
            [e.target.name]: e.target.value
        });
    };

    const {registerAction} = useAuth();

    return (
        <React.Fragment>
            <Dialog
                open={isOpen}
                onClose={closeModal}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        if (registerData.username !== "" && registerData.password !== "" && registerData.email !== "") {
                            registerAction(registerData);
                            return;
                        }
                        event.preventDefault();
                        closeModal();
                    },
                }}
            >
                <DialogTitle>Регистрация</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="username"
                        name="username"
                        label="Логин"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChange}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={handleChange}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="password"
                        name="password"
                        label="Пароль"
                        type="password"
                        fullWidth
                        variant="standard"
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal}>Отменить</Button>
                    <Button type="submit">Зарегистрироваться</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}