import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from "@mui/material/Typography";

function LinearDate() {
    let startProgress = (100 / 30) * (new Date().getSeconds() % 30);
    const [progress, setProgress] = React.useState(startProgress);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((100 / 30) * (new Date().getSeconds() % 30))
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <LinearProgress variant="determinate" color="success" value={progress}
                        sx={{height: 10, position: 'fixed', width: '100%'}}/>
    );
}

export default LinearDate;