import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import {FC, useCallback, useState} from "react";
import axiosInstance from "../../App/AuthProvider/request";
import {CreateModalProps} from "./interfaces";
import Snackbar, {SnackbarCloseReason} from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import QrCodeIcon from "@mui/icons-material/QrCode";
import IconButton from "@mui/material/IconButton";
import {Scanner} from '@yudiel/react-qr-scanner';

export const CreateModal: FC<CreateModalProps> = (props) => {
    let {
        open,
        closeModal,
        updateList
    } = props;

    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);

    const [openAlert, setOpenAlert] = useState(false);
    const changeOpenAlert = useCallback(
        (state: boolean): void => {
            setOpenAlert(state);
        },
        [],
    );

    const handleClose = (
        _event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        changeOpenAlert(false)
    };

    let element_alert;

    if (isError)
        element_alert =
            <Alert onClose={handleClose} severity="error" variant="filled" sx={{width: '100%'}}>{message}</Alert>
    else
        element_alert =
            <Alert onClose={handleClose} severity="success" variant="filled" sx={{width: '100%'}}>{message}</Alert>

    const [qrTotp, setQrTotp] = useState("");
    const handleChange = (e: { target: { value: React.SetStateAction<string>; }; }) => setQrTotp(e.target.value);
    const [openQR, setOpenQR] = useState(false);

    const handleOpenQR = async () => {
        setOpenQR(!openQR);
    }

    const handleCloseAndClear = () => {
        setOpenQR(false)
        setQrTotp("")
        closeModal()
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseAndClear}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        axiosInstance.post('/api/',
                            JSON.stringify({
                                totp: formJson.uri,
                            })
                        )
                            .then(() => {
                                setMessage("Запись добавлена")
                                setIsError(false)
                                changeOpenAlert(true)
                                updateList()
                            })
                            .catch(error => {
                                if (error.response.status === 400) {
                                    setIsError(true)
                                    setMessage("Ошибка в разборе URI при сохранении")
                                    changeOpenAlert(true)
                                    updateList()
                                }
                                if (error.response.status === 409) {
                                    setMessage("Запись с такими данными уже существует")
                                    setIsError(true)
                                    changeOpenAlert(true)
                                    updateList()
                                }
                            })
                        handleCloseAndClear();
                    },
                }}
            >
                <DialogTitle>
                    Создание аккаунта
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Для создания аккаунта введите следующие данные:
                    </DialogContentText>
                    <TextField
                        label="URI"
                        variant="outlined"
                        id="uri"
                        name="uri"
                        fullWidth
                        required
                        value={qrTotp}
                        onChange={handleChange}
                        margin="dense"
                        slotProps={{
                            input: {
                                endAdornment: <>
                                    <IconButton size="large" onClick={handleOpenQR}>
                                        <QrCodeIcon/>
                                    </IconButton>
                                </>
                            }
                        }}
                    />

                    {openQR &&
                        <Scanner components={{audio: false}}
                                 onScan={(result) => {
                                     setQrTotp(result[0].rawValue);
                                     setOpenQR(false)
                                 }}/>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAndClear}>Отменить</Button>
                    <Button type="submit">Создать</Button>
                </DialogActions>
            </Dialog>
            <Snackbar autoHideDuration={2000} open={openAlert} onClose={handleClose}>
                {element_alert}
            </Snackbar>
        </>
    );
}