import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {FC, useState} from "react";
import {useAuth} from "../../App/AuthProvider/AuthProvider";
import {MenuProps} from "../Menu/interfaces";

export const LoginModal: FC<MenuProps> = (props) => {
    const {
        isOpen,
        closeModal
    } = props;

    const [credentials, setCredentials] = useState({
        username: "",
        password: "",
    });

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
        setStatus(true)
    };

    const [status, setStatus] = useState(true);

    const {loginAction, token} = useAuth();

    return (
        <React.Fragment>
            <Dialog
                open={isOpen}
                onClose={() => {
                    setStatus(true);
                    closeModal();
                }}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        if (credentials.username !== "" && credentials.password !== "") {
                            loginAction(credentials);
                            if (token === "")
                                setStatus(false)
                            else
                                setStatus(true)
                        }
                    }
                }}
            >
                <DialogTitle>Авторизация</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Для авторизации введите логин и пароль
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="username"
                        name="username"
                        label="Логин"
                        type="text"
                        fullWidth
                        variant="standard"
                        error={!status}
                        onChange={handleChange}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="password"
                        name="password"
                        label="Пароль"
                        type="password"
                        fullWidth
                        variant="standard"
                        autoComplete="on"
                        onChange={handleChange}
                        error={!status}
                        helperText={status ? "" : "Неверный логин/пароль"}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        closeModal();
                        setStatus(true)
                    }}>Отменить</Button>
                    <Button type="submit">Войти</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}