import {Snackbar} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import React, {FC} from "react";
import {SnackProps} from "./interfaces";

export const SnackbarComponent: FC<SnackProps> = (props) => {
    const {state, closeAlert, message} = props;

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={closeAlert}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            open={state}
            autoHideDuration={6000}
            onClose={closeAlert}
            message={message}
            action={action}
        />
    )
}