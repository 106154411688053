import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {FC, useCallback} from "react";
import {DeleteDialogProps} from "./interfaces";
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {SnackbarComponent} from "../snackbar";
import axiosInstance from "../../App/AuthProvider/request";

export const DeleteDialog: FC<DeleteDialogProps> = (props) => {
    const {open, keyId, changeDialogDelete, updateList} = props;

    const handleDelete = async () => {
        await axiosInstance.delete('/api/' + keyId)
            .then(() => {
                changeDialogDelete()
                changeOpenAlert(true)
                updateList()
            })
    }

    const [openAlert, setOpenAlert] = React.useState(false);
    const changeOpenAlert = useCallback(
        (state: boolean): void => {
            setOpenAlert(state);
        },
        [],
    );

    return (
        <><Dialog
            open={open}
            onClose={changeDialogDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Вы хотите удалить аккаунт?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={changeDialogDelete}>Нет</Button>
                <Button onClick={handleDelete} autoFocus>Да</Button>
            </DialogActions>
        </Dialog><SnackbarComponent autoHideDuration={2000} state={openAlert} message="Запись удалена"
                                    closeAlert={() => changeOpenAlert(false)}/></>
    );
}