import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {FC, useState} from "react";
import {OtpProps} from "./interfaces";
import CardHeader from '@mui/material/CardHeader';
import {Grid2, IconButton, Tooltip} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export const OtpCard: FC<OtpProps> = (props) => {
    const {
        service,
        code_key,
        username,
        editKey,
        deleteKey
    } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleEdit = () => {
        handleClose();
        editKey();
    }

    const handleDelete = () => {
        handleClose();
        deleteKey();
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [title, setTitle] = useState("Скопировать");
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(code_key);
            setTitle("Скопировано");
            setTimeout(() => setTitle("Скопировать"), 2000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    }

    return (
        <><Grid2 size={{xs: 12, sm: 6, md: 4, lg: 3, xl: 3}}>
            <Card variant="outlined">
                <CardHeader
                    action={
                        <IconButton aria-label="settings"
                                    onClick={handleClick}>
                            <MoreVertIcon/>
                        </IconButton>}
                    title={
                        <Tooltip title={service} children={
                            <Typography variant="h5">
                                {service.toString().length < 17
                                    ? `${service}`
                                    : `${service.toString().substring(0, 14)}...`}
                            </Typography>}></Tooltip>
                    }
                    subheader={
                        <Tooltip title={username} children={
                            <Typography variant="subtitle1">
                                {username.toString().length < 24
                                    ? `${username}`
                                    : `${username.toString().substring(0, 20)}...`}
                            </Typography>}></Tooltip>
                    }
                />
                <CardContent>
                    <Tooltip title={title}>
                        <Button color="primary" size="large" endIcon={<ContentCopyIcon/>} onClick={handleCopy}>
                            {code_key}
                        </Button>
                    </Tooltip>
                </CardContent>
            </Card>
        </Grid2>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleEdit}>
                    Изменить
                </MenuItem>
                <MenuItem onClick={handleDelete}>Удалить</MenuItem>
            </Menu></>
    );
}