import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AdbIcon from '@mui/icons-material/Adb';
import {useAuth} from "../../App/AuthProvider/AuthProvider";
import Button from '@mui/material/Button';
import {LoginModal} from "../login";
import {RegisterModal} from "../register";
import {styled} from "@mui/material";

function MenuAppBar() {

    const { logoutAction } = useAuth();

    const handleCloseUserMenu = () => {
        logoutAction()
        handleClickCloseLoginModal();
        handleClickCloseRegisterModal();
    };

    const { token } = useAuth();

    const [openLoginModal, setOpenLoginModal] = React.useState(false);
    const [openRegisterModal, setOpenRegisterModal] = React.useState(false);

    const handleClickOpenLoginModal = () => {
        setOpenLoginModal(true);
    };

    const handleClickCloseLoginModal = () => {
        setOpenLoginModal(false);
    };

    const handleClickOpenRegisterModal = () => {
        setOpenRegisterModal(true);
    };

    const handleClickCloseRegisterModal = () => {
        setOpenRegisterModal(false);
    };

    const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

    return (
        <>
            <AppBar position="fixed">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <AdbIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}/>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: {xs: 'none', md: 'flex'},
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            OTP
                        </Typography>
                        <AdbIcon sx={{display: {xs: 'flex', md: 'none'}, mr: 1}}/>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: {xs: 'flex', md: 'none'},
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            OTP
                        </Typography>
                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>

                        </Box>
                        <Box sx={{flexGrow: 0}}>
                            {token && (
                                <Button onClick={handleCloseUserMenu} color="inherit">Выйти</Button>
                            )}
                            {!token && (
                                <>
                                    <Button color="inherit" onClick={handleClickOpenLoginModal}>Войти</Button>
                                    <Button color="inherit" onClick={handleClickOpenRegisterModal}>Регистрация</Button>
                                </>
                            )}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Offset />
            {!token && (
                <>
                    <LoginModal isOpen={openLoginModal} closeModal={handleClickCloseLoginModal}/>
                    <RegisterModal isOpen={openRegisterModal} closeModal={handleClickCloseRegisterModal}/>
                </>
            )}
        </>
    );
}

export default MenuAppBar;