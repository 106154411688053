import {
    createContext, useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import axiosInstance from "./request";

const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const [user, setUser] = useState(localStorage.getItem("user") || null);
    const [token, setToken] = useState(localStorage.getItem("token") || "");

    const loginAction = useCallback(async (data) => {
            await axiosInstance.post("/auth/login", data)
                .then(response => {
                    if (response.status === 200) {
                        setUser(response.data.username);
                        setToken(response.data.token);
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("user", response.data.username);
                    }
                })
                .catch(error => {

                })
        }, []
    )


    const logoutAction = () => {
        setUser(null);
        setToken("");
        localStorage.removeItem("token");
        localStorage.removeItem("user");
    };

    const registerAction = async (data) => {
        await axiosInstance.post("/auth/register", data)
            .then(response => {
                if (response.status === 200) {
                    setUser(response.data.username);
                    setToken(response.data.token);
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("user", response.data.username);
                }
            })
            .catch(error => {
                if (error.response?.status === 409)
                    console.error(error.message);
                if (error.response?.status === 406)
                    console.error(error.message);
            })
    }

    const contextValue = useMemo(
        () => ({
            token,
            setToken,
            loginAction,
            logoutAction,
            registerAction
        }),
        [loginAction, registerAction, token]
    );

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );

};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};