import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token")
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        config.headers["Content-Type"] = "application/json"
        return config;
    },
    (error) => Promise.reject(error),
);

export default axiosInstance;