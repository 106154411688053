import MenuAppBar from "../components/Menu";
import OtpCards from "../components/OtpCards";
import React, {FC} from "react";
import {useAuth} from "../App/AuthProvider/AuthProvider";
import LinearDate from "../components/linear";

/** корневой роутер приложения */
export const Pages: FC = () => {
    const {token} = useAuth();

    return (
        <>
            <MenuAppBar/>
            {token && (<><LinearDate/><OtpCards/></>)}
        </>
    );
}