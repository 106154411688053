import {Card, Grid2, styled} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {OtpCard} from "../OtpCard";
import Box from '@mui/material/Box';
import {DeleteDialog} from "../deleteDialog";
import {EditModal} from "../editModal";
import CardContent from "@mui/material/CardContent";
import axiosInstance from "../../App/AuthProvider/request";
import {CreateModal} from "../createModal";
import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';
import Container from "@mui/material/Container";
import {useAuth} from "../../App/AuthProvider/AuthProvider";

export default function OtpCards() {
    const { logoutAction } = useAuth();
    const [otps, setOtps] = useState<any[]>([]);
    const [isOpenDialogDelete, setIsOpenDialogDelete] =
        useState<boolean>(false);

    const [editId, setEditId] = useState<string>('');
    const [editUsername, setEditUsername] = useState<string>('');
    const [editService, setEditService] = useState<string>('');

    const changeDialogEdit = useCallback(
        (id: string, username: string, service: string, isOpen: boolean): void => {
            setEditId(id);
            setEditUsername(username);
            setEditService(service);
            setOpenModal(isOpen);
        },
        [],
    );


    const [deleteId, setDeleteId] = useState<string>('');

    const changeDialogDelete = useCallback(
        (id: string, isOpen: boolean): void => {
            setDeleteId(id);
            setIsOpenDialogDelete(isOpen);
        },
        [],
    );

    const updateList = () => {
        axiosInstance.get("/api/all/token")
            .then(response => {
                setOtps(response.data);
            })
            .catch(error => {
                if (error.response?.status === 403) {
                    logoutAction()
                }
            })
    }

    useEffect(() => {
        const timer = setInterval(() => {
            if (new Date().getSeconds() % 30 === 0)
                updateList()
        }, 1000);

        updateList()
        return () => {
            clearInterval(timer);
        };
    }, []);


    const [openModal, setOpenModal] = React.useState(false);
    const [openCreateModal, setOpenCreateModal] = React.useState(false);

    const handleOpen = () => {
        setOpenModal(true);
    }

    const handleClose = () => {
        setOpenModal(false);
    }

    const handleCreateOpen = () => {
        setOpenCreateModal(true);
    }

    const handleCreateClose = () => {
        setOpenCreateModal(false);
    }

    return (
        <>
            <Container fixed sx={{}}>
                <Box sx={{width: '100%'}}>
                    <Grid2 container spacing={1} mt={2}>
                        {otps.map(otp => (
                            <OtpCard
                                code_key={otp.token}
                                service={otp.service}
                                username={otp.username}
                                id={otp.id}
                                key={otp.id}
                                editKey={() => changeDialogEdit(otp.id, otp.username, otp.service, true)}
                                deleteKey={() => changeDialogDelete(otp.id, true)}
                            />
                        ))}
                        <Grid2 size={{xs: 12, sm: 6, md: 4, lg: 3, xl: 3}}>
                            <Card variant="outlined" style={{height: "100%", display: "flex", alignItems: "center"}}>
                                <CardContent style={{display: "block", textAlign: "center"}}>
                                    <IconButton onClick={handleCreateOpen} size="large">
                                        <AddIcon/>
                                    </IconButton>
                                </CardContent>
                            </Card>
                        </Grid2>
                    </Grid2>
                </Box>
            </Container>
            <CreateModal
                open={openCreateModal}
                closeModal={handleCreateClose}
                updateList={updateList}/>
            <EditModal
                open={openModal}
                keyId={editId}
                username={editUsername}
                service={editService}
                openModal={handleOpen}
                closeModal={handleClose}
                updateList={updateList}
            />
            <DeleteDialog
                open={isOpenDialogDelete}
                keyId={deleteId}
                changeDialogDelete={() => setIsOpenDialogDelete(false)}
                updateList={updateList}
            />
        </>
    );
}