import './index.css';
import {Pages} from "../pages";
import AuthProvider from "./AuthProvider/AuthProvider";

const App = () => {

    return (
        <AuthProvider>
            <Pages/>
        </AuthProvider>
    )
};

export default App;